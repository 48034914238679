import { gql } from 'graphql-request';
import internalGQL from 'helpers/internalGQL';
import { createMutationHook, useApiQuery } from 'queries/common';

import internalAPI from '../../helpers/internalAPI';

const BASE_CURRENT_USER_URL = 'internal_api/v1/current_user';
const BASE_INTERNAL_USER_URL = 'internal_api/v1/users';
const USER = 'user';

// Current User
export function useCurrentAuthContextGql(options = {}) {
  const gqlQuery = gql`
    query {
      currentAuthContext {
        __typename

        id
        name
        email
        isSuperDuperUser
        featureFlagIdentity

        account {
          id
          name
          tier
        }

        defaultOrganization {
          id
          name
          slug

          metadata {
            forcedTimeZone
          }
        }

        organizations {
          edges {
            id
            role
            enabled
            hasRestrictedAccess
            isLinkedToSlack
            icalUrl
            tags

            token

            node {
              id
              name
              slug

              hasSsoConfigured

              metadata {
                showPriority
                forcedTimeZone
              }
            }
          }
        }
      }
    }
  `;

  const query = useApiQuery(
    ['current_user', 'detail', 'current_user'],
    async () => {
      const data = await internalGQL().request(gqlQuery);

      return data;
    },
    options,
  );

  return {
    ...query,
    data: query?.data?.currentAuthContext || {},
  };
}

const downloadCurrentSlackAvatar = (body) =>
  internalAPI.put('internal_api/v1/current_user/avatar/slack', body);

export const useDownloadCurrentSlackAvatar = createMutationHook(
  downloadCurrentSlackAvatar,
);

const updateCurrentUser = (params) =>
  internalAPI.put(`${BASE_CURRENT_USER_URL}`, params);

export const useUpdateCurrentUser = createMutationHook(updateCurrentUser);

const updateCurrentUserPassword = (params) =>
  internalAPI.post(`${BASE_CURRENT_USER_URL}/password`, params);

export const useUpdateCurrentUserPassword = createMutationHook(
  updateCurrentUserPassword,
);

const updateCurrentUserTags = (params) =>
  internalAPI.put(`${BASE_CURRENT_USER_URL}/tags`, params);

export const useUpdateCurrentUserTags = createMutationHook(
  updateCurrentUserTags,
);

export const useInternalUser = (id, options = {}) => {
  const queryFn = () => internalAPI.get(`${BASE_INTERNAL_USER_URL}/${id}`);

  const query = useApiQuery([USER, 'detail', id], queryFn, options);

  return {
    ...query,
    data: query.data?.data || {},
  };
};

const internalDeleteUser = (id) =>
  internalAPI.delete(`${BASE_INTERNAL_USER_URL}/${id}`);

export const useInternalDeleteUser = createMutationHook(internalDeleteUser);

const internalUpdateUser = (params) =>
  internalAPI.patch(`${BASE_INTERNAL_USER_URL}/${params.id}`, params);

export const useInternalUpdateUser = createMutationHook(internalUpdateUser);

const organizationInviteUser = (params) =>
  internalAPI.post('internal_api/v1/invitations', params);

export const useOrganizationInviteUser = createMutationHook(
  organizationInviteUser,
);
